import { useEffect, useState } from "react";
import { BottomBtn } from "../../../../../shared"
import { Divider, Modal, message } from "antd";
import { ReactComponent as Close } from "../../../../../assets/images/icon/close.svg"
import { LectureInfoRow } from "../../lectureInfoRow";
import { lectureDatePeriodFormat } from "../../../../../utils";
import { createMobileReservation } from "../../../../../service";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../../../modules";
import { getLectureListByCenterThunk } from "../../../../../modules/lectureByCenter";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface IProps {
    yogaLecture: IYogaLectureByIdAndMemberId
    memberId: number
    requestYogaLectureById: Function
    issuedTicketId: number
}

const ReservationWithModalInLectureDetail = ({ yogaLecture, memberId, requestYogaLectureById, issuedTicketId }: IProps) => {
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const { lectureByCenterData } = useSelector((state: RootState) => state.lectureByCenter.lectureByCenterList);
    const [isReservationLoading, setIsReservationLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isReservationDisableModalOpen, setIsReservationDisableModalOpen] = useState<boolean>(false);
    const [isReservationCompleteModalOpen, setIsReservationCompleteModalOpen] = useState<boolean>(false);

    message.config({ top: 200 })

    const reservationButtonClick = () => {
        if (isReservationLoading) return;
        if (yogaLecture.nowReservationCnt >= yogaLecture.maxReservationMember) {
            setIsReservationDisableModalOpen(true)
            return;
        }
        setIsOpen(false);
        setIsReservationLoading(true);
        createMobileReservation({ memberId: memberId, issuedTicketId: issuedTicketId, yogaLectureId: yogaLecture.id, })
            .then(() => {
                setIsReservationCompleteModalOpen(true);
                if (lectureByCenterData && lectureByCenterData.centerId)
                    dispatch(getLectureListByCenterThunk(lectureByCenterData.centerId, lectureByCenterData.selectedDate ? lectureByCenterData.selectedDate : dayjs().format("YYYY-MM-DD"), dayjs().add(1, "M").format("YYYY-MM-DD")));
            })
            .catch((error) => { console.error("Error :", error); error.response.data.includes("Error YogaLecture is Passed.") && message.error("예약 할 수 없는 수업 입니다.") })
            .finally(() => setIsReservationLoading(false))
    }

    const onClickSuccessReservation = () => {
        setIsReservationCompleteModalOpen(false);
        navigate('/my')
    }
    useEffect(() => {
        if (!isReservationCompleteModalOpen)
            requestYogaLectureById()
    }, [isReservationCompleteModalOpen])

    return <>
        <BottomBtn
            onClick={() => setIsOpen(true)}
            text={isReservationLoading ? "예약 중" : "예약하기"} />

        <Modal
            centered
            open={isOpen}
            styles={{ footer: { margin: 0 } }}
            closeIcon={<Close width={24} height={24} stroke="var(--secondarys400)" />}
            onCancel={() => setIsOpen(false)}
            footer={null}>
            <div>
                <div className="sub-title/st3" style={{ paddingBlock: 6 }}>수업을 예약할까요?</div>
                <div className="body/b1 secondary/s800">수업 내용을 확인하고 예약하기 버튼을 눌러주세요.</div>
            </div>

            <div style={{ marginTop: 16, padding: 12, backgroundColor: 'var(--secondarys100)', color: 'var(--secondarys700)' }}>
                <div className="sub-title/st3 base/black" style={{ textAlign: 'center' }}>{yogaLecture.yogaLectureName}</div>
                <LectureInfoRow label="시간" value={lectureDatePeriodFormat(yogaLecture.startDateTime, yogaLecture.endDateTime)} style={{ marginTop: 12 }} />
                <LectureInfoRow label="장소" value={`${yogaLecture.yogaCenterName} ${yogaLecture.room}`} />
                <LectureInfoRow label="코치" value={`${yogaLecture.teacherName} 코치`} />
            </div>
            <div className="sub-title/st3"
                style={{ backgroundColor: 'var(--primaryp500)', paddingBlock: "12px", borderRadius: '5px', textAlign: 'center', marginTop: '15px', cursor: 'pointer' }}
                onClick={() => { reservationButtonClick() }}>예약하기</div>
        </Modal>
        <Modal
            centered
            open={isReservationDisableModalOpen}
            onCancel={() => setIsReservationDisableModalOpen(false)}
            footer={null}>
            <div>
                <div className="sub-title/st3" style={{ paddingBlock: 6 }}>예약 할 수 없습니다</div>
                <div className="body/b1 secondary/s800">정원초과</div>
            </div>
            <div className="sub-title/st3"
                style={{ backgroundColor: 'black', color: 'white', padding: '15px', borderRadius: '5px', textAlign: 'center', marginTop: '15px', cursor: 'pointer' }}
                onClick={() => { setIsReservationDisableModalOpen(false) }}>닫기</div>
        </Modal>
        <Modal centered open={isReservationCompleteModalOpen} styles={{ content: { padding: "16px" }, footer: { margin: 0 } }} onCancel={() => setIsReservationCompleteModalOpen(false)} footer={false} closeIcon={false}>
            <div>
                <div className="sub-title/st3">예약을 완료했습니다</div>
            </div>
            <div style={{ marginTop: 16, padding: 12, backgroundColor: 'var(--secondarys100)' }}>
                {yogaLecture.yogaCenterId === 3
                    ? <>
                        <div style={{ textAlign: 'center' }}>
                            <div className="sub-title/st3 base/black">Member's Lounge</div>
                            <div className="body/b2 secondary/s700" style={{ marginTop: 12 }}>선릉 멤버십 라운지에서<br />파지티브호텔만의 웰니스를 경험해보세요</div>
                            <Divider style={{ margin: '12px 0' }} />
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                                <div className="sub-title/st3 base/black">이용 안내</div>
                                <div className="sub-title/st1 secondary/s700">09:00 - 21:30</div>
                            </div>
                            <div className="body/b2 secondary/s700" style={{ marginTop: 12 }}>
                                웰니스 드링크<br />웰니스 제품 체험<br />1:1 맞춤 상담
                            </div>
                        </div>
                    </>
                    : <>
                        <div className="sub-title/st3 base/black" style={{ textAlign: 'center' }}>{yogaLecture.yogaLectureName}</div>
                        <LectureInfoRow label="시간" value={lectureDatePeriodFormat(yogaLecture.startDateTime, yogaLecture.endDateTime)} style={{ marginTop: 12 }} />
                        <LectureInfoRow label="장소" value={`${yogaLecture.yogaCenterName} ${yogaLecture.room}`} />
                        <LectureInfoRow label="코치" value={`${yogaLecture.teacherName} 코치`} />
                    </>}

            </div>
            <div className="sub-title/st3"
                style={{ backgroundColor: 'black', color: 'white', padding: "12px 20px", borderRadius: '5px', textAlign: 'center', marginTop: '16px', cursor: 'pointer' }}
                onClick={() => onClickSuccessReservation()}>예약 내역 확인하기</div>
        </Modal>
    </>
}
export { ReservationWithModalInLectureDetail }