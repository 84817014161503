import { Flex } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom"
import { ReservationBadge } from "../../../reservationBadge";

interface IProps {
    dataList: Array<IYogaLectureListByRangeDateAndYogaCenterIdGetResponse> | null
}

const LectureCardInReservation = ({ dataList }: IProps) => {
    const navigate = useNavigate();

    return <>{dataList === null
        ? <div style={{ textAlign: "center", fontSize: "14px" }}>조회할 수업이 없습니다.</div>
        : <>{dataList.length === 0
            ? <div className="body/b2 secondary/s600" style={{ textAlign: 'center', marginTop: 24 }}>예약 가능한 수업이 없습니다</div>
            : <div style={{ paddingTop: 4 }}>{dataList.map((yogaLecture, idx) => {
                return <div
                    key={idx}
                    style={{ marginTop: 20, padding: '8px 0' }}
                    onClick={() => navigate(`/lecture/${yogaLecture.id}`)}
                >
                    <Flex style={{ justifyContent: 'space-between' }} gap={16}>
                        <div style={{ width: '25%' }}>
                            <img src={yogaLecture.profileImageUrl} alt="수업사진" width={'100%'} style={{ borderRadius: "999px" }} />
                        </div>
                        <div style={{ width: '75%' }}>
                            <Flex style={{ justifyContent: 'space-between' }}>
                                <div>
                                    <div className="sub-title/st1">{yogaLecture.yogaProgramName && (yogaLecture.yogaCenterName !== "강남") ? yogaLecture.yogaProgramName : ""}</div>
                                    <div className="sub-title/st3" style={{ marginTop: 2 }}>{yogaLecture.yogaLectureName}</div>
                                </div>
                                <div>
                                    {yogaLecture.isReservation
                                        ? <ReservationBadge status="Complete" />
                                        : dayjs().isAfter(yogaLecture.startDateTime)
                                            ? <ReservationBadge status="Close" />
                                            : (yogaLecture.maxReservationMember - yogaLecture.nowReservationCnt > 0)
                                                ? <ReservationBadge status="Available" />
                                                : <ReservationBadge status="Close" />
                                    }
                                </div>
                            </Flex>
                            <Flex style={{ justifyContent: 'space-between', marginTop: 12 }}>
                                <div className="body/b1 secondary/s500">{yogaLecture.startDateTime?.slice(11, 16)} - {yogaLecture.endDateTime?.slice(11, 16)}</div>
                                <div className="body/b1 secondary/s500">{yogaLecture.teacherName} 코치 | {yogaLecture.yogaCenterName} {yogaLecture.room}</div>
                            </Flex>
                        </div>
                    </Flex>
                </div>
            })}</div>}
        </>
    }</>
}

export { LectureCardInReservation }