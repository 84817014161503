import { ReactComponent as PositivehotelLogo } from "../../assets/images/positivehotelLogo.svg";
import { Divider, Tabs, TabsProps } from "antd";
import './index.css'
const ScehduleInfo = () => {

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <div style={{ fontSize: '16px' }}>강남</div>,
            children: <>
                <div style={{ padding: '20px' }}>
                    <div style={{ marginTop: '40px', fontSize: '24px', fontWeight: '600' }}>PRICE</div>
                    <div style={{ marginTop: '36px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>1회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>40,000원</div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>10회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>350,000원 <span style={{ color: '#7D7D7D' }}> / 회당 35,000</span></div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>20회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>600,000원<span style={{ color: '#7D7D7D' }}> / 회당 30,000</span></div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>40회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>1,000,000원<span style={{ color: '#7D7D7D' }}> / 회당 25,000</span></div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>60회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>1,200,000원<span style={{ color: '#7D7D7D' }}> / 회당 20,000</span></div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                    </div>
                </div>
                <div style={{ marginTop: '100px', fontSize: '24px', fontWeight: '600', padding: '0 20px' }}>SCHEDULE</div>
                <div style={{ marginTop: '30px', backgroundColor: '#F4F4F4', overflow: 'scroll' }}>
                    <img src={'https://img.positivehotel.io/2024/9/30/PH_Yoga_October_2024_App_Gangnam.png'} width="540px" alt="이미지" />
                </div>
            </>,
        },
        {
            key: '2',
            label: <div style={{ fontSize: '16px' }}>도산 / 선릉 (ALL PASS)</div>,
            children: <>
                <div style={{ padding: '20px' }}>
                    <div style={{ marginTop: '40px', fontSize: '24px', fontWeight: '600' }}>PRICE</div>
                    <div style={{ marginTop: '36px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>1회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>50,000원</div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>10회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>450,000원 <span style={{ color: '#7D7D7D' }}> / 회당 45,000</span></div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>20회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>800,000원<span style={{ color: '#7D7D7D' }}> / 회당 40,000</span></div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>40회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>1,400,000원<span style={{ color: '#7D7D7D' }}> / 회당 35,000</span></div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>60회권</div>
                            <div style={{ fontSize: '14px', fontWeight: '400' }}>1,800,000원<span style={{ color: '#7D7D7D' }}> / 회당 30,000</span></div>
                        </div>
                        <Divider style={{ margin: '11px 0', borderColor: '#B3B3B3' }} />
                    </div>
                </div>
                <div style={{ marginTop: '100px', fontSize: '24px', fontWeight: '600', padding: '0 20px' }}>SCHEDULE</div>
                <div style={{ fontSize: '22px', fontWeight: '500', marginTop: '30px', padding: '0 20px' }}>도산</div>
                <div style={{ marginTop: '30px', backgroundColor: '#F4F4F4', overflow: 'scroll' }}>
                    <img src={'https://img.positivehotel.io/2024/9/30/PH_Yoga_October_2024_App_Dosan.png'} width="540px" alt="이미지" />
                </div>
                <div style={{ fontSize: '22px', fontWeight: '500', marginTop: '80px', padding: '0 20px' }}>선릉</div>
                <div style={{ marginTop: '30px', backgroundColor: '#F4F4F4', overflow: 'scroll' }}>
                    <img src={'https://img.positivehotel.io/2024/9/30/PH_Yoga_October_2024_App_Seolleung.png'} width="540px" alt="이미지" />
                </div>
            </>,
        },

    ];


    return <>
        <div>
            <div style={{ margin: "auto 20px" }}>
                <div style={{ textAlign: "center", padding: "46px 0 27px 0" }}>
                    <PositivehotelLogo />
                </div>
                <div style={{ marginTop: '60px', color: "#000", textAlign: "center", fontWeight: '600', fontSize: "18px", display: "flex", height: "20px", flexDirection: "column", justifyContent: "center" }}>
                    파지티브호텔은 국내 최초<br />
                    웰니스 키친과 요가 스튜디오가 결합된<br />
                    웰니스 공간입니다.<br /><br />
                    아래로 스크롤하여 스튜디오의 클래스<br />
                    스케줄을 확인하실 수 있습니다.
                </div>
            </div>
            <div >
                <Tabs
                    className="notificationTabs"
                    defaultActiveKey="1"
                    items={items}
                    style={{ marginTop: '90px' }}
                    tabBarStyle={{ marginBottom: 0 }} />
            </div>
            <div style={{ marginTop: '100px' }}>
                <div style={{ textAlign: 'center' }}><img src={'https://img.positivehotel.io/2023/12/22/logo.png'} alt="이미지" style={{ width: '100px', height: '100px' }} /></div>
                <div style={{ fontSize: '18px', fontWeight: '600', textAlign: 'center', margin: '20px 0 40px 0' }}>앱을 통해 편리하게<br />
                    파지티브호텔 요가 전 지점을<br />
                    이용하실 수 있습니다</div>
                <div onClick={() => window.location.href = 'https://positivehotel.page.link/Y2uY'}
                    style={{ padding: '16px', backgroundColor: 'black', color: 'white', fontSize: '18px', fontWeight: '500', textAlign: 'center', margin: '0 22px', borderRadius: '5px', cursor: 'pointer' }}>다운로드 하러 가기</div>
            </div>
            <div style={{ height: '100px' }}></div>
        </div>

    </>
}
export default ScehduleInfo;